<template>
  <v-app>
    <v-main>
      <router-view />
      <div class="d-flex align-center justify-center flex-column">
        <router-link class="black--text" to="/"
          >--> Version primary</router-link
        >
        <router-link class="black--text" to="/color"
          >--> Version secondary</router-link
        >
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
.bgP {
  background: linear-gradient(120deg, #336173, #3c93b6);
  border-radius: 0.3rem;
}
.bgS {
  background: linear-gradient(120deg, #3c93b6, #f6a141);
  border-radius: 0.3rem;
}
.icon {
  background-color: #3c93b6;
  border-radius: 0.3rem;
}
</style>
