<template>
  <v-container justify="space-around" class="my-8 bgS">
    <div class="d-flex mb-3 justify-space-around">
      <v-img src="img/logo-ellipsia.png" max-width="200px"></v-img>
      <div class="white--text mt-4 text-center">
        <h2>Calculez votre TCO avec Ellipsia</h2>
        <p>
          Total Cost of Ownership : Coûts directs et indirects du Service Poste
          de travail
        </p>
      </div>
    </div>
    <v-sheet>
      <v-expansion-panels accordion focusable v-model="voletActif">
        <!--Panneau des infos générales avec le TOTAL du TCO-->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Informations générales
            <v-spacer></v-spacer>
            TCO estimé :
            <v-card
              class="ml-2 px-2 pt-1 pb-1 text-right secondary"
              dark
              max-width="100"
              >{{ totalCosts }} €</v-card
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              label="Nombre de personnes à qui au moins un environnement de travail est fourni"
              id="nbUsers"
              :rules="rulesRequired"
              hide-details="auto"
              type="number"
              v-model="nbUsers"
            ></v-text-field>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Nombre de jours travaillés par année"
                  oninput="if(this.value > 365) this.value = 365;"
                  hide-details="auto"
                  type="number"
                  v-model="nbDaysWorked"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              Nombre de jours ouvrés, moins les congés, moins les RTT,...
            </v-tooltip>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Taux Journalier Moyen (en euros HT)  à considérer pour les collaborateurs DSI"
                  :rules="rulesRequired"
                  hide-details="auto"
                  type="number"
                  v-model="TJM"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">€</template>
                </v-text-field>
              </template>
              Coût moyen HT d'une journée de travail des personnes de la DSI
              <br />
              (pour estimer les coûts de maintenance, de support, d'ingénierie
              etc.)
            </v-tooltip>
            <v-combobox
              v-model="clientSectors"
              :items="possibleSectors"
              small-chips
              deletable-chips
              label="Secteur d'activité"
              placeholder="Autres : préciser"
            ></v-combobox>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--Panneau sur le coût du matériel-->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Matériels utilisateurs
            <v-spacer></v-spacer>
            <v-card class="px-2 pt-1 pb-1 text-right" max-width="100"
              >{{ HardwareCosts }} €</v-card
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-switch
              color="secondary"
              v-model="defHWCostsDirectly"
              label="Cocher pour entrer directement un coût annuel (sinon compléter les éléments ci-dessous)"
            >
            </v-switch>
            <v-text-field
              v-if="defHWCostsDirectly"
              label="Coût annuel du matériel (ordinateurs, écrans, accessoires) mis à disposition par utilisateur"
              v-model="HWCostsState"
              type="number"
              :rules="rulesRequired"
            >
              <template slot="append">€</template>
            </v-text-field>
            <div v-else>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center my-2">
                    <p v-on="on" v-bind="attrs">Comment remplir ?</p>
                    <v-img
                      src="img/icone-question.svg"
                      max-width="50px"
                      max-height="50px"
                      v-on="on"
                      v-bind="attrs"
                    />
                  </div>
                </template>
                <ul>
                  <li>
                    Définir pour chaque type de matériels mis à disposition des
                    utilisateurs :
                  </li>
                  <ul>
                    <li>le coût unitaire HT</li>
                    <li>le nombre total</li>
                    <li>la durée d'amortissement</li>
                  </ul>
                  <li>Supprimer les modèles de matériel vides</li>
                </ul>
              </v-tooltip>
              <p
                v-if="nbUsers === null || nbUsers === '' || nbUsers === 0"
                @click="voletActif = 0"
              >
                Bien renseigner le nombre d'utilisateurs (dans Informations
                générales)
              </p>
              <p
                v-if="
                  nbHardware === 0 || nbHardware === null || nbHardware === ''
                "
              >
                Les utilisateurs n'ont aucune dotation matérielle
              </p>
              <p
                v-if="
                  nbUsers !== null &&
                  nbUsers !== 0 &&
                  nbUsers !== '' &&
                  nbHardware !== null &&
                  nbHardware !== 0 &&
                  nbHardware !== ''
                "
              >
                Les utilisateurs ont en moyenne
                {{ Math.round((nbHardware / nbUsers) * 100) / 100 }}
                appareil(s) ({{ nbUsers }} utilisateurs pour
                {{ nbHardware }} matériels)
              </p>
              <div class="d-flex flex-wrap justify-start align-center">
                <v-card
                  v-for="(HWCategory, index) in HWCategories"
                  :key="index"
                  class="mx-4 mb-5 d-inline-block px-2"
                  width="245"
                  height="350"
                >
                  <div class="d-flex flex-column align-center text-center">
                    <v-card-subtitle
                      style="font-weight: bold"
                      @click="modifyHW(index)"
                    >
                      {{ HWCategory.name }}
                    </v-card-subtitle>
                    <v-img
                      class="icon mx-auto"
                      :src="HWCategory.icon"
                      max-width="80"
                      @click="modifyHW(index)"
                    />
                  </div>
                  <v-text-field
                    label="Coût unitaire HT"
                    v-model="HWCategory.cost"
                    type="number"
                    :rules="rulesRequired"
                  >
                    <template slot="append">€</template>
                  </v-text-field>
                  <v-text-field
                    label="Nombre total fourni"
                    v-model="HWCategory.quantity"
                    type="number"
                    :rules="rulesRequired"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Durée d'amortissement"
                    v-model="HWCategory.dureeAmo"
                    type="number"
                    :rules="rulesRequired"
                  >
                    <template slot="append">an(s)</template>
                  </v-text-field>
                </v-card>
                <v-card
                  @click="showNewHWForm = true"
                  class="mx-4 mb-5 d-inline-block px-2"
                  width="245"
                  height="245"
                >
                  <div class="d-flex flex-column align-center text-center">
                    <v-card-title class="headline"> Plus </v-card-title>
                    <v-img
                      class="icon mx-auto"
                      max-width="80"
                      src="img/icone-autre.svg"
                    />
                    <v-card-text>
                      Personnaliser d’autres modèles de matériel
                    </v-card-text>
                  </div>
                </v-card>
              </div>

              <p>
                Coût moyen du package d'accessoires fourni à chaque utilisateur
                (souris, clavier, casque, sacoche…)
              </p>
              <div>
                <v-card
                  class="mx-1 my-3 d-inline-block px-2 text-center"
                  width="325"
                >
                  <v-card-text>
                    Accessoires Postes Portables (et Tablettes)
                  </v-card-text>
                  <v-layout align-content-space-around>
                    <div class="d-inline-block mx-auto">
                      <v-img
                        class="d-inline-block"
                        src="img/icone-casque.svg"
                        width="40"
                      />
                      <v-img
                        class="d-inline-block"
                        src="img/icone-sac.svg"
                        width="40"
                      />
                      <v-img
                        class="d-inline-block"
                        src="img/icone-souris.svg"
                        width="40"
                      />
                      <v-img
                        class="d-inline-block"
                        src="img/icone-cadena.svg"
                        width="40"
                      />
                    </div>
                  </v-layout>
                  <v-text-field
                    label="Coût unitaire HT"
                    v-model="prixAccessoirsLaptops"
                    type="number"
                    :rules="rulesRequired"
                  >
                    <template slot="append">€</template>
                  </v-text-field>
                </v-card>
                <v-card
                  class="mx-1 my-3 d-inline-block px-2 text-center"
                  width="325"
                >
                  <v-card-text> Accessoires Postes Fixes </v-card-text>
                  <v-layout align-content-space-around>
                    <div class="d-inline-block mx-auto">
                      <v-img
                        class="d-inline-block"
                        src="img/icone-clavier.svg"
                        max-width="40"
                      />
                      <v-img
                        class="d-inline-block"
                        src="img/icone-casque.svg"
                        max-width="40"
                      />
                      <v-img
                        class="d-inline-block"
                        src="img/icone-webcam.svg"
                        max-width="40"
                      />
                      <v-img
                        class="d-inline-block"
                        src="img/icone-souris.svg"
                        max-width="40"
                      />
                    </div>
                  </v-layout>
                  <v-text-field
                    label="Coût unitaire HT"
                    v-model="prixAccessoirsDesktops"
                    type="number"
                    :rules="rulesRequired"
                  >
                    <template slot="append">€</template>
                  </v-text-field>
                </v-card>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--Panneau sur le coût de l'ingénierie du poste de travail-->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Ingénierie et MCO
            <v-spacer></v-spacer>
            <v-card class="px-2 pt-1 pb-1 text-right" max-width="100"
              >{{ IngenieringCosts }} €</v-card
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Nombre de personnes travaillant au maintien de l'image des postes de travail"
                  id="ImageETP"
                  :rules="rulesRequired"
                  hide-details="auto"
                  type="number"
                  v-model="ImageETP"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">(ETP)</template>
                </v-text-field>
              </template>
              Ingénieurs travaillant sur le master, l'image du poste de travail
            </v-tooltip>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Nombre de personnes travaillant sur la maintenance de SCCM ou équivalent"
                  id="SCCMmcoETP"
                  :rules="rulesRequired"
                  hide-details="auto"
                  type="number"
                  v-model="SCCMmcoETP"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">(ETP)</template>
                </v-text-field>
              </template>
              SCCM = Solution Microsoft de Gestion du Parc <br />
              Note : Pour les postes managés dans le Cloud, indiquer les
              personnes en charge <br />du maintien de l'ingénierie d'Intune ou
              JAMF pour les Macs par exemple
            </v-tooltip>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Nombre de personnes travaillant sur la gestion des Patchs de sécurité"
                  id="PatchMgtETP"
                  :rules="rulesRequired"
                  hide-details="auto"
                  type="number"
                  v-model="PatchMgtETP"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">(ETP)</template>
                </v-text-field>
              </template>
              Personnes responsables du maintien du bon niveau des patchs
              <br />et du déploiement de ceux-ci
            </v-tooltip>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Nombre de personnes travaillant sur la Sécurisation du poste"
                  id="SecurityETP"
                  :rules="rulesRequired"
                  hide-details="auto"
                  type="number"
                  v-model="SecurityETP"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">(ETP)</template>
                </v-text-field>
              </template>
              Personnes responsables de la gestion des règles DLP (Data Loss
              Protection), <br />de la mise à jour des empreintes antivirus et
              autres règles de conformité
            </v-tooltip>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Coût unitaire HT du Packaging et de la distribution d'une application"
                  id="PackageCost"
                  :rules="rulesRequired"
                  hide-details="auto"
                  type="number"
                  v-model="PackageCost"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">€</template>
                </v-text-field>
              </template>
              Note : Si pas de coût unitaire, calculer en ETP * TJM * Nombre de
              jours ouvrés dans l'année<br />
              puis diviser par le nombre d'applications traitées
            </v-tooltip>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Nombre d'applications packagées"
                  id="PackageNumber"
                  :rules="rulesRequired"
                  hide-details="auto"
                  type="number"
                  v-model="PackageNumber"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              Nombres d'applications packagées disponibles en installation
              <br />automatique (via un Centre logiciel SCCM par exemple)
            </v-tooltip>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Pourcentage de packages renouvellés par an"
                  id="AnnualPackageRenewalRate"
                  :rules="rulesRequired"
                  oninput="if(this.value > 100) this.value = 100;"
                  hide-details="auto"
                  type="number"
                  v-model="AnnualPackageRenewalRate"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">%</template>
                </v-text-field>
              </template>
              Cas des mises à jour d'applications nécessitant une évolution de
              package
            </v-tooltip>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--Panneau sur le coût des Services d'infrastructure-->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Services d'infrastructures
            <v-spacer></v-spacer>
            <v-card class="px-2 pt-1 pb-1 text-right" max-width="100"
              >{{ InfraServicesCosts }} €</v-card
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-stepper v-model="StepperInfra">
              <v-stepper-header>
                <v-stepper-step
                  color="secondary"
                  :complete="StepperInfra > 1"
                  step="1"
                  @click="StepperInfra = 1"
                  >Coûts liés au Réseau</v-stepper-step
                >
                <v-divider></v-divider>
                <v-stepper-step
                  color="secondary"
                  :complete="StepperInfra > 2"
                  step="2"
                  @click="StepperInfra = 2"
                  >Coûts des Infra. de Gestion du parc</v-stepper-step
                >
                <v-divider></v-divider>
                <v-stepper-step
                  color="secondary"
                  :complete="StepperInfra > 3"
                  step="3"
                  @click="StepperInfra = 3"
                  >Coûts de l'Antivirus</v-stepper-step
                >
                <v-divider></v-divider>

                <v-stepper-step
                  color="secondary"
                  :complete="StepperInfra > 4"
                  step="4"
                  @click="StepperInfra = 4"
                  >Coûts de l'IAM</v-stepper-step
                >
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Nombre d'Architectes Réseau"
                        id="NetworkEngineeringETP"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="NetworkEngineeringETP"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">ETP</template>
                      </v-text-field>
                    </template>
                    Nombre de personnes travaillant sur l'architecture globale
                    du réseau. <br />Note : Possibilité de mettre uniquement
                    ceux travaillant sur le réseau Bureautique <br />et dans ce
                    cas mettre 100% pour la question suivante
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Quote part du réseau pour le Poste de travail"
                        id="NetworkWSRate"
                        :rules="rulesRequired"
                        oninput="if(this.value > 100) this.value = 100;"
                        hide-details="auto"
                        type="number"
                        v-model="NetworkWSRate"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">%</template>
                      </v-text-field>
                    </template>
                    Estimation de la quote part (en %) de Postes de travail par
                    rapport aux Applications <br />ou aux Services
                    d'infrastructures
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT du WiFi"
                        id="WiFiCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="WiFiCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Amortissement des AP WiFi et coût HT des licences de gestion
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT des liens WAN"
                        id="WANCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="WANCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Abonnement annuel des liens WAN (inclus support du
                    fournisseur)
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel du VPN client"
                        id="VPNCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="VPNCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Coût HT des licences VPN et coût éventuel des serveurs
                  </v-tooltip>
                  <v-btn
                    class="mr-2 mt-3"
                    text
                    color="secondary"
                    @click="StepperInfra = 2"
                    >suivant</v-btn
                  >
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-text-field
                    label="Coût annuel HT des licences SCCM, Intune ou autre"
                    id="SCCMLicenceCost"
                    :rules="rulesRequired"
                    hide-details="auto"
                    type="number"
                    v-model="SCCMLicenceCost"
                  >
                    <template slot="append">€</template>
                  </v-text-field>

                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût HT des serveurs (matériel) qui hébergent le service"
                        id="SCCMInfraCosts"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="SCCMInfraCosts"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS (ex : Intune)
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Durée d'amortissement des serveurs"
                        id="SCCMInfraDepreciation"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="SCCMInfraDepreciation"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">an</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT des licences (système d'exploitation) des serveurs hébergeant le service"
                        id="SCCMInfraLicenceCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="SCCMInfraLicenceCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-btn
                    text
                    color="grey"
                    class="mr-2 mt-3"
                    @click="StepperInfra = 1"
                    >précédent</v-btn
                  >
                  <v-btn
                    class="mr-2 mt-3"
                    text
                    color="secondary"
                    @click="StepperInfra = 3"
                    >suivant</v-btn
                  >
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT de l'antivirus"
                        id="AntivirusLicenceCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="AntivirusLicenceCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : Si licence par utilisateur, multiplier par le nombre
                    d'utilisateurs
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût HT des serveurs (matériel) qui hébergent le service"
                        id="AVInfraCosts"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="AVInfraCosts"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Durée d'amortissement des serveurs"
                        id="AVInfraDepreciation"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="AVInfraDepreciation"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">an</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT des licences (système d'exploitation) des serveurs hébergeant le service"
                        id="AVInfraLicence"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="AVInfraLicence"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-btn
                    text
                    color="grey"
                    class="mr-2 mt-3"
                    @click="StepperInfra = 2"
                    >précédent</v-btn
                  >
                  <v-btn
                    class="mr-2 mt-3"
                    text
                    color="secondary"
                    @click="StepperInfra = 4"
                    >suivant</v-btn
                  >
                </v-stepper-content>
                <v-stepper-content step="4">
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Quote part de l'IAM pour le Poste de travail"
                        id="IAMWSRate"
                        :rules="rulesRequired"
                        oninput="if(this.value > 100) this.value = 100;"
                        hide-details="auto"
                        type="number"
                        v-model="IAMWSRate"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">%</template>
                      </v-text-field>
                    </template>
                    IAM = Identity Access Management<br />
                    Quote part des solutions de gestion de l'identité <br />et
                    des accès attribués au poste de travail
                  </v-tooltip>

                  <v-text-field
                    label="Coût annuel HT des licences de l'Active Directory"
                    id="ADLicenceCost"
                    :rules="rulesRequired"
                    hide-details="auto"
                    type="number"
                    v-model="ADLicenceCost"
                  >
                    <template slot="append">€</template>
                  </v-text-field>

                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût HT des serveurs (matériel) qui hébergent le service"
                        id="ADInfraCosts"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="ADInfraCosts"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS (ex : Azure AD)
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Durée d'amortissement des serveurs"
                        id="ADInfraDepreciation"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="ADInfraDepreciation"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">an</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT des licences (système d'exploitation) des serveurs hébergeant le service"
                        id="ADInfraLicenceCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="ADInfraLicenceCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT du MFA"
                        id="MFACosts"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="MFACosts"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    MFA = Multi Factor Authentication
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT du SSO"
                        id="SSOCosts"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="SSOCosts"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    SSO = Single Sign On
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT des licences de la PKI"
                        id="IPKILicenceCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="IPKILicenceCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    PKI = Public Key Infrastructure
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT des serveurs (matériel) qui hébergent le service PKI"
                        id="IPKIInfraCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="IPKIInfraCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Durée d'amortissement des serveurs"
                        id="IPKIInfraDepreciation"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="IPKIInfraDepreciation"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">an</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT des licences (Système d'exploitation) des serveurs hébergeant le service"
                        id="IPKIInfraLicenceCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="IPKIInfraLicenceCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-btn
                    text
                    color="grey"
                    class="mr-2 mt-3"
                    @click="StepperInfra = 3"
                    >précédent</v-btn
                  >
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--Panneau sur le coût des services standards-->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Services bureautique standards
            <v-spacer></v-spacer>
            <v-card class="px-2 pt-1 pb-1 text-right" max-width="100"
              >{{ ServicesCosts }} €</v-card
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-stepper v-model="StepperServiceStandard">
              <v-stepper-header>
                <v-stepper-step
                  color="secondary"
                  :complete="StepperServiceStandard > 1"
                  step="1"
                  @click="StepperServiceStandard = 1"
                  >Service d'impression</v-stepper-step
                >
                <v-divider></v-divider>
                <v-stepper-step
                  color="secondary"
                  :complete="StepperServiceStandard > 2"
                  step="2"
                  @click="StepperServiceStandard = 2"
                  >Services bureautique</v-stepper-step
                >
                <v-divider></v-divider>
                <v-stepper-step
                  color="secondary"
                  :complete="StepperServiceStandard > 3"
                  step="3"
                  @click="StepperServiceStandard = 3"
                  >Gestion documentaire</v-stepper-step
                >
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-text-field
                    label="Coût annuel HT des licences de la solution d'impression"
                    id="PrintingLicenceCost"
                    :rules="rulesRequired"
                    hide-details="auto"
                    type="number"
                    v-model="PrintingLicenceCost"
                  >
                    <template slot="append">€</template>
                  </v-text-field>

                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût HT des serveurs (matériel) qui hébergent le service"
                        id="PrintingInfraCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="PrintingInfraCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Durée d'amortissement des serveurs"
                        id="PrintingInfraDepreciation"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="PrintingInfraDepreciation"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">an</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT des licences (Système d'exploitation) des serveurs hébergeant le service"
                        id="PrintingInfraLicenceCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="PrintingInfraLicenceCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>

                  <v-text-field
                    label="Nombre d'imprimantes"
                    id="NbPrinters"
                    hide-details="auto"
                    type="number"
                    v-model="NbPrinters"
                  >
                  </v-text-field>

                  <v-text-field
                    label="Coût unitaire HT des imprimantes"
                    id="PrintersCosts"
                    :rules="rulesRequired"
                    hide-details="auto"
                    type="number"
                    v-model="PrintersCosts"
                  >
                    <template slot="append">€</template>
                  </v-text-field>

                  <v-text-field
                    label="Durée d'amortissement des imprimantes"
                    id="PrintingInfraDepreciation"
                    :rules="PrintersDepreciation"
                    hide-details="auto"
                    type="number"
                    v-model="PrintersDepreciation"
                  >
                    <template slot="append">an</template>
                  </v-text-field>

                  <v-btn
                    class="mr-2 mt-3"
                    text
                    color="secondary"
                    @click="StepperServiceStandard = 2"
                    >suivant</v-btn
                  >
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-text-field
                    label="Coût annuel HT de licences par utilisateur pour la messagerie"
                    id="MailLicences"
                    :rules="rulesRequired"
                    hide-details="auto"
                    type="number"
                    v-model="MailLicences"
                  >
                    <template slot="append">€</template>
                  </v-text-field>

                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût HT des serveurs (matériel) qui hébergent le service"
                        id="MailInfraCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="MailInfraCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS (ex : Office 365)
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Durée d'amortissement des serveurs"
                        id="MailInfraDepreciation"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="MailInfraDepreciation"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">an</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT des licences (Système d'exploitation) des serveurs hébergeant le service"
                        id="MailInfraLicenceCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="MailInfraLicenceCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT des licences par utilisateur de la suite bureautique (ex : Suite Office)"
                        id="OfficeSuiteLicenceCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="OfficeSuiteLicenceCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : Si abonnement 365, compris dans les licences de
                    messagerie
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT des licences par utilisateur de l'outil de messagerie instantannée"
                        id="ChatLicence"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="ChatLicence"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : Si abonnement 365, compris dans les licences de
                    messagerie
                  </v-tooltip>
                  <v-btn
                    text
                    color="grey"
                    class="mr-2 mt-3"
                    @click="StepperServiceStandard = 1"
                    >précédent</v-btn
                  >
                  <v-btn
                    class="mr-2 mt-3"
                    text
                    color="secondary"
                    @click="StepperServiceStandard = 3"
                    >suivant</v-btn
                  >
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT de la solution de GED"
                        id="GEDLicence"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="GEDLicence"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : Si licence par utilisateur, multiplier par le nombre
                    d'utilisateurs
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût HT des serveurs (matériel) qui hébergent le service"
                        id="MailInfraCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="GEDInfraCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS (ex : SharePoint
                    Online)
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Durée d'amortissement des serveurs"
                        id="GEDInfraDepreciation"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="GEDInfraDepreciation"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">an</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Coût annuel HT de licences (Système d'exploitation) des serveurs hébergeant le service"
                        id="GEDInfraLicenceCost"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="GEDInfraLicenceCost"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">€</template>
                      </v-text-field>
                    </template>
                    Note : 0 si le service est en mode SaaS
                  </v-tooltip>
                  <v-btn
                    text
                    color="grey"
                    class="mr-2 mt-3"
                    @click="StepperServiceStandard = 2"
                    >précédent</v-btn
                  >
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--Panneau sur le coût du support aux utilisateurs-->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Support utilisateurs
            <v-spacer></v-spacer>
            <v-card class="px-2 pt-1 pb-1 text-right" max-width="100"
              >{{ SupportCosts }} €</v-card
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-subheader>Support de distant :</v-subheader>
            <v-switch
              color="secondary"
              v-model="DistantSupportTicketBased"
              label="Coût au ticket"
            ></v-switch>
            <div v-if="DistantSupportTicketBased">
              <v-text-field
                label="Nombre de tickets ouverts au support distant à l'année"
                id="DistantSupportTicketYealyQte"
                :rules="rulesRequired"
                hide-details="auto"
                type="number"
                v-model="DistantSupportTicketYealyQte"
              ></v-text-field>

              <v-text-field
                label="Coût unitaire HT du ticket ouvert au support distant"
                id="DistantSupportTicketCost"
                :rules="rulesRequired"
                hide-details="auto"
                type="number"
                v-model="DistantSupportTicketCost"
              >
                <template slot="append">€</template>
              </v-text-field>
            </div>
            <div v-else>
              <v-text-field
                label="Nombre de techniciens pour le support niveau 1 (HelpDesk)"
                id="L1SupportETP"
                :rules="rulesRequired"
                hide-details="auto"
                type="number"
                v-model="L1SupportETP"
                v-bind="attrs"
                v-on="on"
              >
                <template slot="append">ETP</template>
              </v-text-field>

              <v-text-field
                label="Nombre de techniciens pour le support niveau 2"
                id="L2SupportETP"
                :rules="rulesRequired"
                hide-details="auto"
                type="number"
                v-model="L2SupportETP"
              >
                <template slot="append">ETP</template>
              </v-text-field>

              <v-text-field
                label="Nombre de techniciens pour le support niveau 3"
                id="L3SupportETP"
                :rules="rulesRequired"
                hide-details="auto"
                type="number"
                v-model="L3SupportETP"
              >
                <template slot="append">ETP</template>
              </v-text-field>
            </div>
            <v-slider
              color="secondary"
              track-color="accent"
              v-model="DistantTicketsWSRate"
              label="Quote part des tickets liés aux postes de travail"
              thumb-label
              step="5"
            ></v-slider>
            <v-subheader>Support de proximité :</v-subheader>
            <v-switch
              color="secondary"
              v-model="proximitySupportTicketBased"
              label="Coût au ticket"
            ></v-switch>
            <div v-if="proximitySupportTicketBased">
              <v-text-field
                label="Nombre de tickets ouverts au support de proximité à l'année"
                id="ProximityTicketYealryQte"
                :rules="rulesRequired"
                hide-details="auto"
                type="number"
                v-model="ProximityTicketYealryQte"
              ></v-text-field>

              <v-text-field
                label="Coût unitaire HT du ticket ouvert au support de proximité"
                id="proximitySupportTicketCost"
                :rules="rulesRequired"
                hide-details="auto"
                type="number"
                v-model="proximitySupportTicketCost"
              >
                <template slot="append">€</template>
              </v-text-field>
            </div>
            <div v-else>
              <v-text-field
                label="Nombre de techniciens pour le support de proximité"
                id="ProximitySupportETP"
                :rules="rulesRequired"
                hide-details="auto"
                type="number"
                v-model="ProximitySupportETP"
              >
                <template slot="append">ETP</template>
              </v-text-field>
            </div>
            <v-slider
              color="secondary"
              track-color="accent"
              v-model="proximityTicketsWSrate"
              label="Quote part des tickets liés aux postes de travail"
              thumb-label
              step="5"
            ></v-slider>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--Panneau sur le coût de la consomation électrique des PCs-->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Frais généraux (consommation électrique)
            <v-spacer></v-spacer>
            <v-card class="px-2 pt-1 pb-1 text-right" max-width="100"
              >{{ consoCosts }} €</v-card
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-list-item v-for="(HWCategory, i) in HWCategories" :key="i">
                <v-list-item-icon>
                  <v-img
                    :src="HWCategory.icon"
                    width="40"
                    class="rounded icon"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="HWCategory.name"
                  ></v-list-item-title>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :id="HWCategory.name"
                        :rules="rulesRequired"
                        hide-details="auto"
                        type="number"
                        v-model="HWCategory.conso"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">kWh</template>
                      </v-text-field>
                    </template>
                    En moyenne : 200 kWh pour les ordinateurs portables,<br />600
                    kWh pour les ordinateurs fixes, 39,5 kWh pour les écrans
                  </v-tooltip>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-text-field
              label="Prix du kWh"
              id="prixKWH"
              :rules="rulesRequired"
              hide-details="auto"
              type="number"
              v-model="prixKWH"
            >
              <template slot="append">€</template>
            </v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- <donut-chart :chartData="chartData" :width="150" :height="150" /> -->
    </v-sheet>
    <div
      class="d-flex justify-space-around align-center text-center white--text"
    >
      <v-btn
        class="ml-2 mt-3"
        outlined
        color="white"
        @click="
          clientWhishes.slides = true;
          showClientForm = true;
        "
        >finaliser et Télécharger</v-btn
      >
      <p class="pt-7">
        Recevez votre TCO au format PowerPoint, notre Livre Blanc sur le
        pilotage<br />financier du Poste de travail et rejoignez notre
        communauté d’intérêt !
      </p>
      <v-badge
        :value="clientWhishes.joinCommunity"
        icon="mdi-check-bold"
        color="accent"
        overlap
        bottom
        bordered
        offset-x="21"
        offset-y="14"
      >
        <v-btn
          class="mr-2 mt-3"
          outlined
          color="white"
          @click="clientWhishes.joinCommunity = !clientWhishes.joinCommunity"
        >
          Rejoindre la communauté !
        </v-btn>
      </v-badge>
    </div>
    <!--Formulaire de modification d'un type de matériel-->
    <v-overlay :value="showModifyHWForm">
      <v-card
        color="white"
        light
        class="mx-1 d-inline-block py-3 px-5"
        width="400"
      >
        <v-card-title class="headline">
          <v-text-field
            label="Matériel"
            v-model="newHW.name"
            type="text"
            :maxlength="maxChar"
          >
          </v-text-field>
        </v-card-title>
        <div class="d-flex justify-space-around">
          <v-card
            width="78"
            class="mb-3"
            :color="newHW.icon === 'img/icone-portable.svg' ? 'accent' : 'grey'"
            @click="newHW.icon = 'img/icone-portable.svg'"
          >
            <div
              class="d-flex flex-column justify-center align-center white--text"
            >
              Portable
              <v-img src="img/icone-portable.svg" width="50" />
            </div>
          </v-card>
          <v-card
            width="78"
            class="mb-3"
            :color="newHW.icon === 'img/icone-fixe.svg' ? 'accent' : 'grey'"
            @click="newHW.icon = 'img/icone-fixe.svg'"
          >
            <div
              class="d-flex flex-column justify-center align-center white--text"
            >
              Fixe
              <v-img src="img/icone-fixe.svg" width="50" />
            </div>
          </v-card>
          <v-card
            width="78"
            class="mb-3"
            :color="newHW.icon === 'img/icone-tablette.png' ? 'accent' : 'grey'"
            @click="newHW.icon = 'img/icone-tablette.png'"
          >
            <div
              class="d-flex flex-column justify-center align-center white--text"
            >
              Tablette
              <v-img src="img/icone-tablette.png" width="50" />
            </div>
          </v-card>
          <v-card
            width="78"
            class="mb-3"
            :color="newHW.icon === 'img/icone-ecran.svg' ? 'accent' : 'grey'"
            @click="newHW.icon = 'img/icone-ecran.svg'"
          >
            <div
              class="d-flex flex-column justify-center align-center white--text"
            >
              Ecran
              <v-img src="img/icone-ecran.svg" width="50" />
            </div>
          </v-card>
        </div>
        <v-card-actions>
          <v-btn text color="primary" @click="saveHWModification">ok</v-btn>
          <v-btn text color="grey" @click="cancellHWModification"
            >annuler</v-btn
          >
          <v-btn text color="error" @click="deleteHW(newHW.modify)"
            >supprimer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-overlay>
    <!--Formulaire d'ajout d'un type de matériel-->
    <v-overlay :value="showNewHWForm">
      <v-card
        color="white"
        light
        class="mx-1 d-inline-block py-3 px-5"
        width="400"
      >
        <v-card-title class="headline">
          <v-text-field
            label="Matériel"
            v-model="newHW.name"
            type="text"
            :maxlength="maxChar"
          >
          </v-text-field>
        </v-card-title>
        <div class="d-flex justify-space-around">
          <v-card
            width="78"
            class="mb-3"
            :color="newHW.icon === 'img/icone-portable.svg' ? 'accent' : 'grey'"
            @click="newHW.icon = 'img/icone-portable.svg'"
          >
            <div
              class="d-flex flex-column justify-center align-center white--text"
            >
              Portable
              <v-img src="img/icone-portable.svg" width="50" />
            </div>
          </v-card>
          <v-card
            width="78"
            class="mb-3"
            :color="newHW.icon === 'img/icone-fixe.svg' ? 'accent' : 'grey'"
            @click="newHW.icon = 'img/icone-fixe.svg'"
          >
            <div
              class="d-flex flex-column justify-center align-center white--text"
            >
              Fixe
              <v-img src="img/icone-fixe.svg" width="50" />
            </div>
          </v-card>
          <v-card
            width="78"
            class="mb-3"
            :color="newHW.icon === 'img/icone-tablette.png' ? 'accent' : 'grey'"
            @click="newHW.icon = 'img/icone-tablette.png'"
          >
            <div
              class="d-flex flex-column justify-center align-center white--text"
            >
              Tablette
              <v-img src="img/icone-tablette.png" width="50" />
            </div>
          </v-card>
          <v-card
            width="78"
            class="mb-3"
            :color="newHW.icon === 'img/icone-ecran.svg' ? 'accent' : 'grey'"
            @click="newHW.icon = 'img/icone-ecran.svg'"
          >
            <div
              class="d-flex flex-column justify-center align-center white--text"
            >
              Ecran
              <v-img src="img/icone-ecran.svg" width="50" />
            </div>
          </v-card>
        </div>
        <v-card-actions>
          <v-btn text color="primary" @click="saveNewHW">ok</v-btn>
          <v-btn text color="grey" @click="cancellHWModification"
            >annuler</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-overlay>
    <!--formulaire pour prendre les information de contact du client-->
    <v-overlay :value="showClientForm">
      <v-card
        color="white"
        light
        class="mx-1 d-inline-block px-4 pt-4"
        width="600"
      >
        <v-form ref="clientForm" v-model="contactFormValid" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field
                v-model="clientFirstName"
                :maxlength="maxNameChar"
                label="Prénom"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="clientName"
                :maxlength="maxNameChar"
                label="Nom"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Email professionel"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="entreprise"
                :maxlength="maxNameChar"
                label="Entreprise"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="poste"
                :maxlength="maxNameChar"
                label="Poste / Fonction"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-text> Je souhaite recevoir :</v-card-text>
          <div class="d-flex justify-start align-center">
            <v-badge
              :value="clientWhishes.livreBlanc"
              icon="mdi-check-bold"
              color="accent"
              overlap
              bottom
              bordered
              offset-x="21"
              offset-y="14"
            >
              <v-card
                class="px-2 mx-2 d-flex justify-space-around align-center white--text"
                width="175px"
                height="45px"
                :color="clientWhishes.livreBlanc ? 'accent' : 'grey'"
                @click="clientWhishes.livreBlanc = !clientWhishes.livreBlanc"
              >
                <v-img
                  contain
                  height="45px"
                  width="45px"
                  src="img/icone-livre-blanc.svg"
                />
                Le Livre Blanc
              </v-card>
            </v-badge>
            <v-badge
              :value="clientWhishes.slides"
              icon="mdi-check-bold"
              color="accent"
              overlap
              bottom
              bordered
              offset-x="21"
              offset-y="14"
            >
              <v-card
                class="px-2 mx-2 d-flex text-center justify-space-around align-center white--text"
                width="175px"
                height="45px"
                :color="clientWhishes.slides ? 'accent' : 'grey'"
                @click="clientWhishes.slides = !clientWhishes.slides"
              >
                <v-img
                  contain
                  height="40px"
                  width="40px"
                  src="img/icone-slides.svg"
                  class="mx-2"
                />
                Ma Synthèse (format ppt.)
              </v-card>
            </v-badge>
          </div>
          <v-btn
            :disabled="!contactFormValid"
            color="primary"
            text
            class="mr-3 my-5"
            @click="validateClientForm"
            >Envoyer</v-btn
          >
          <v-btn text class="mr-3 my-5" color="grey" @click="resetClientForm"
            >Annuler</v-btn
          >
        </v-form>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

// import { getAnalytics } from "firebase/analytics";

import { child, getDatabase, get, push, ref, update } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCRxCN8vL7IJAc_zNGuLF2NHf7xdDrrwMU",

  authDomain: "tcoapp-689e6.firebaseapp.com",

  databaseURL:
    "https://tcoapp-689e6-default-rtdb.europe-west1.firebasedatabase.app",

  projectId: "tcoapp-689e6",

  storageBucket: "tcoapp-689e6.appspot.com",

  messagingSenderId: "691884151753",

  appId: "1:691884151753:web:6223316e7659602bc64e18",

  measurementId: "G-L9ZM1KX66Q",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

// eslint-disable-next-line

// const analytics = getAnalytics(app);

const database = getDatabase(app);

// import donutChart from "@/components/donutChart.vue";
//import HelloWorld from "@/components/HelloWorld.vue";
//import firebase from "firebase/app";
//import "firebase/database";

/*
var firebaseConfig = {
  apiKey: "AIzaSyCRxCN8vL7IJAc_zNGuLF2NHf7xdDrrwMU",
  authDomain: "tcoapp-689e6.firebaseapp.com",
  projectId: "tcoapp-689e6",
  databaseURL:
    "https://tcoapp-689e6-default-rtdb.europe-west1.firebasedatabase.app/",
  storageBucket: "tcoapp-689e6.appspot.com",
  messagingSenderId: "691884151753",
  appId: "1:691884151753:web:6223316e7659602bc64e18",
  measurementId: "G-L9ZM1KX66Q",
};
//Initialize Firebase
firebase.initializeApp(firebaseConfig);
*/
/* var firebaseConfig = {
  apiKey: "AIzaSyCRxCN8vL7IJAc_zNGuLF2NHf7xdDrrwMU",
  authDomain: "tcoapp-689e6.firebaseapp.com",
  databaseURL: "https://tcoapp-689e6.firebaseio.com"
}; */

//var donneesClientRef = firebase.database().ref("/donneesTCOClients");

export default {
  name: "CalculetteView",
  data: () => ({
    clientFirstName: "",
    clientName: "",
    email: "",
    entreprise: "",
    clientSectors: null,
    poste: "",
    clientWhishes: {
      livreBlanc: false,
      slides: false,
      joinCommunity: false,
    },
    nbUsers: null,
    nbDaysWorked: 216,
    TJM: null,
    HWCostsState: null,
    HWCategories: [
      {
        name: "Postes Portables Standards",
        icon: "img/icone-portable.svg",
        quantity: null,
        cost: null,
        dureeAmo: null,
        conso: 200,
      },
      {
        name: "Postes Fixes",
        icon: "img/icone-fixe.svg",
        quantity: null,
        cost: null,
        dureeAmo: null,
        conso: 600,
      },
      {
        name: "Postes VIP",
        icon: "img/icone-portable.svg",
        quantity: null,
        cost: null,
        dureeAmo: null,
        conso: 200,
      },
      {
        name: "Ecrans",
        icon: "img/icone-ecran.svg",
        quantity: null,
        cost: null,
        dureeAmo: null,
        conso: 39.5,
      },
    ],
    prixAccessoirsLaptops: null,
    prixAccessoirsDesktops: null,
    prixKWH: 0.15,
    defHWCostsDirectly: false,
    possibleSectors: [
      "Industrie",
      "Banque & Assurance",
      "Distribution",
      "Énergie",
      "Secteur Public",
      "Collectivité et Administration",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "L'adresse mail doit être valide",
    ],
    rulesRequired: [(value) => !!value || "Requis"],
    maxNameChar: 15,
    maxChar: 25,
    voletActif: null,
    showNewHWForm: false,
    showModifyHWForm: false,
    showClientForm: false,
    contactFormValid: false,
    newHW: {
      name: null,
      icon: "",
      quantity: null,
      cost: null,
      dureeAmo: null,
      conso: 600,
      modify: null,
    },
    benchMarkConsoElec: {
      PC: 600,
      Portable: 200,
      PowerPC: 900,
      Tablette: 12,
      Ecran: 39.5,
    },
    ImageETP: null,
    SCCMmcoETP: null,
    PatchMgtETP: null,
    SecurityETP: null,

    PackageCost: null,
    PackageNumber: null,
    AnnualPackageRenewalRate: null,

    NetworkEngineeringETP: null,
    NetworkWSRate: 100,
    StepperInfra: 1,
    WiFiCost: null,
    WANCost: null,
    VPNCost: null,

    SCCMLicenceCost: null,
    SCCMInfraCosts: null,
    SCCMInfraDepreciation: 4,
    SCCMInfraLicenceCost: null,

    AntivirusLicenceCost: null,
    AVInfraCosts: null,
    AVInfraDepreciation: 4,
    AVInfraLicence: null,

    IAMWSRate: null,

    ADLicenceCost: null,
    ADInfraCosts: null,
    ADInfraDepreciation: 4,
    ADInfraLicenceCost: null,

    MFACosts: null,

    SSOCosts: null,

    IPKILicenceCost: null,
    IPKIInfraCost: null,
    IPKIInfraDepreciation: 4,
    IPKIInfraLicenceCost: null,

    StepperServiceStandard: 1,

    PrintingLicenceCost: null,
    PrintingInfraCost: null,
    PrintingInfraDepreciation: 4,
    PrintingInfraLicenceCost: null,
    NbPrinters: null,
    PrintersCosts: null,
    PrintersDepreciation: 4,

    MailLicences: null,
    MailInfraCost: null,
    MailInfraDepreciation: 4,
    MailInfraLicenceCost: null,

    OfficeSuiteLicenceCost: null,

    ChatLicence: null,

    GEDLicence: null,
    GEDInfraCost: null,
    GEDInfraDepreciation: 4,
    GEDInfraLicenceCost: null,

    proximitySupportTicketBased: false,
    ProximitySupportETP: null,
    ProximityTicketYealryQte: null,
    proximitySupportTicketCost: null,
    proximityTicketsWSrate: 100,

    DistantSupportTicketBased: false,
    L1SupportETP: null,
    L2SupportETP: null,
    L3SupportETP: null,
    DistantSupportTicketYealyQte: null,
    DistantSupportTicketCost: null,
    DistantTicketsWSRate: 80,
  }),
  computed: {
    nbHW: function () {
      var nbHW = 0;
      for (let HWCategory of this.HWCategories) {
        nbHW += HWCategory.quantity;
      }
      return nbHW;
    },
    nbHardware: function () {
      var nbHW = parseInt(0);
      var messageToLog = "mon nombre de Hardaware est : ";
      for (let HWCategory of this.HWCategories) {
        if (
          !(HWCategory.quantity === null) &&
          HWCategory.icon !== "img/icone-ecran.svg"
        ) {
          messageToLog += HWCategory.quantity + " +";
          nbHW = parseInt(nbHW) + parseInt(HWCategory.quantity);
        }
      }
      messageToLog += "= " + nbHW;
      console.log(messageToLog);
      return nbHW;
    },
    HardwareCosts: function () {
      if (this.nbUsers == null || this.nbUsers == 0) return 0;
      if (this.defHWCostsDirectly)
        return this.HWCostsState === null ? 0 : this.HWCostsState;
      var HardwareCosts = 0;
      var tmpHWCosts;
      for (let HWCategory of this.HWCategories) {
        tmpHWCosts =
          (HWCategory.cost * HWCategory.quantity) / HWCategory.dureeAmo;
        HardwareCosts += isNaN(tmpHWCosts) ? 0 : tmpHWCosts;
        if (
          HWCategory.icon === "img/icone-portable.svg" ||
          HWCategory.icon === "img/icone-tablette.png"
        ) {
          HardwareCosts += HWCategory.quantity * this.prixAccessoirsLaptops;
        } else if (HWCategory.icon === "img/icone-fixe.svg") {
          HardwareCosts += HWCategory.quantity * this.prixAccessoirsDesktops;
        }
      }
      HardwareCosts = Math.round(((HardwareCosts / this.nbUsers) * 100) / 100);
      return isNaN(HardwareCosts) ? 0 : HardwareCosts;
    },
    IngenieringCosts: function () {
      if (this.nbUsers == null || this.nbUsers == 0 || this.nbUsers == "")
        return 0;
      var IngenieringCosts = 0;
      if (this.TJM !== null) {
        IngenieringCosts +=
          this.ImageETP === null
            ? 0
            : this.ImageETP * this.TJM * this.nbDaysWorked;
        IngenieringCosts +=
          this.SCCMmcoETP === null
            ? 0
            : this.SCCMmcoETP * this.TJM * this.nbDaysWorked;
        IngenieringCosts +=
          this.PatchMgtETP === null
            ? 0
            : this.PatchMgtETP * this.TJM * this.nbDaysWorked;
        IngenieringCosts +=
          this.SecurityETP === null
            ? 0
            : this.SecurityETP * this.TJM * this.nbDaysWorked;
      }
      if (this.PackageCost !== null || this.PackageNumber !== null) {
        IngenieringCosts +=
          (this.PackageCost *
            this.PackageNumber *
            this.AnnualPackageRenewalRate) /
          100;
      }

      IngenieringCosts = Math.round(
        ((IngenieringCosts / this.nbUsers) * 100) / 100
      );

      return IngenieringCosts;
    },
    InfraServicesCosts: function () {
      if (this.nbUsers == null || this.nbUsers == 0 || this.nbUsers == "")
        return 0;

      var NetworkCosts = 0;
      var SCCMCosts = 0;
      var AVCosts = 0;
      var IAMCosts = 0;
      var InfraServicesCosts = 0;

      NetworkCosts +=
        this.NetworkEngineeringETP === null
          ? 0
          : this.NetworkEngineeringETP *
            this.TJM *
            (this.NetworkWSRate / 100) *
            this.nbDaysWorked;

      if (this.WiFiCost != null) NetworkCosts = this.WiFiCost;
      if (this.WANCost != null)
        NetworkCosts = NetworkCosts * 1 + this.WANCost * 1;
      if (this.VPNCost != null)
        NetworkCosts = NetworkCosts * 1 + this.VPNCost * 1;
      NetworkCosts = NetworkCosts * (this.NetworkWSRate / 100);

      if (this.SCCMLicenceCost != null)
        SCCMCosts = SCCMCosts * 1 + this.SCCMLicenceCost * 1;
      if (this.SCCMInfraCosts != null)
        SCCMCosts =
          SCCMCosts * 1 + this.SCCMInfraCosts / this.SCCMInfraDepreciation;
      if (this.SCCMInfraLicenceCost != null)
        SCCMCosts = SCCMCosts * 1 + this.SCCMInfraLicenceCost * 1;

      if (this.AntivirusLicenceCost != null)
        AVCosts = AVCosts * 1 + this.AntivirusLicenceCost * 1;
      if (this.AVInfraCosts != null)
        AVCosts = AVCosts * 1 + this.AVInfraCosts / this.AVInfraDepreciation;
      if (this.AVInfraLicence != null)
        AVCosts = AVCosts * 1 + this.AVInfraLicence * 1;

      if (this.ADLicenceCost != null)
        IAMCosts = IAMCosts * 1 + this.ADLicenceCost * 1;
      if (this.ADInfraCosts != null)
        IAMCosts = IAMCosts * 1 + this.ADInfraCosts / this.ADInfraDepreciation;
      if (this.ADInfraLicenceCost != null)
        IAMCosts = IAMCosts * 1 + this.ADInfraLicenceCost * 1;
      if (this.MFACosts != null) IAMCosts = IAMCosts * 1 + this.MFACosts * 1;
      if (this.SSOCosts != null) IAMCosts = IAMCosts * 1 + this.SSOCosts * 1;

      if (this.IPKILicenceCost != null)
        IAMCosts = IAMCosts * 1 + this.IPKILicenceCost * 1;
      if (this.IPKIInfraCost != null)
        IAMCosts =
          IAMCosts * 1 + this.IPKIInfraCost / this.IPKIInfraDepreciation;
      if (this.IPKIInfraLicenceCost != null)
        IAMCosts = IAMCosts * 1 + this.IPKIInfraLicenceCost * 1;
      IAMCosts = IAMCosts * (this.IAMWSRate / 100);

      InfraServicesCosts = Math.round(
        (((NetworkCosts * 1 + SCCMCosts * 1 + AVCosts * 1 + IAMCosts * 1) /
          this.nbUsers) *
          100) /
          100
      );
      return InfraServicesCosts;
    },
    ServicesCosts: function () {
      if (this.nbUsers == null || this.nbUsers == 0 || this.nbUsers == "")
        return 0;

      var ServicesCosts = 0;
      var PrintingCosts = 0;
      var OfficeCosts = 0;
      var GEDCosts = 0;

      if (this.PrintingLicenceCost != null)
        PrintingCosts = this.PrintingLicenceCost;
      if (this.PrintingInfraCost != null)
        PrintingCosts =
          PrintingCosts * 1 +
          this.PrintingInfraCost / this.PrintingInfraDepreciation;
      if (this.PrintingInfraLicenceCost != null)
        PrintingCosts = PrintingCosts * 1 + this.PrintingInfraLicenceCost * 1;
      if (this.NbPrinters != null && this.PrintersCosts != null)
        PrintingCosts =
          PrintingCosts * 1 +
          (this.PrintersCosts / this.PrintersDepreciation) * this.NbPrinters;

      PrintingCosts = PrintingCosts / this.nbUsers;

      if (this.MailLicences != null) OfficeCosts = this.MailLicences;
      if (this.MailInfraCost != null)
        OfficeCosts =
          OfficeCosts * 1 +
          this.MailInfraCost / this.MailInfraDepreciation / this.nbUsers;
      if (this.MailInfraLicenceCost != null)
        OfficeCosts =
          OfficeCosts * 1 + this.MailInfraLicenceCost / this.nbUsers;
      if (this.OfficeSuiteLicenceCost != null)
        OfficeCosts = OfficeCosts * 1 + this.OfficeSuiteLicenceCost * 1;
      if (this.ChatLicence != null)
        OfficeCosts = OfficeCosts * 1 + this.ChatLicence * 1;

      if (this.GEDLicence != null) GEDCosts = this.GEDLicence;
      if (this.GEDInfraCost != null)
        GEDCosts = GEDCosts * 1 + this.GEDInfraCost / this.GEDInfraDepreciation;
      if (this.GEDInfraLicenceCost != null)
        GEDCosts = GEDCosts * 1 + this.GEDInfraLicenceCost * 1;

      GEDCosts = GEDCosts / this.nbUsers;

      ServicesCosts = Math.round(
        ((PrintingCosts * 1 + OfficeCosts * 1 + GEDCosts * 1) * 100) / 100
      );

      return ServicesCosts;
    },
    DistantSupportCost: function () {
      if (this.DistantSupportTicketBased) {
        if (
          this.DistantSupportTicketYealyQte === null ||
          this.DistantSupportTicketCost === null
        )
          return 0;
        return (
          (this.DistantSupportTicketYealyQte *
            this.DistantSupportTicketCost *
            this.DistantTicketsWSRate) /
          100
        );
      }
      var SupportETP = +0;
      if (this.TJM == null) return 0;
      if (this.L1SupportETP !== null) SupportETP = Number(this.L1SupportETP);
      if (this.L2SupportETP !== null) SupportETP += Number(this.L2SupportETP);
      if (this.L3SupportETP !== null) SupportETP += Number(this.L3SupportETP);

      return (
        (SupportETP *
          this.TJM *
          this.DistantTicketsWSRate *
          this.nbDaysWorked) /
        100
      );
    },
    ProximitySupportCost: function () {
      if (this.proximitySupportTicketBased) {
        if (
          this.ProximityTicketYealryQte === null ||
          this.proximitySupportTicketCost === null
        )
          return 0;
        return (
          (this.ProximityTicketYealryQte *
            this.proximitySupportTicketCost *
            this.proximityTicketsWSrate) /
          100
        );
      }
      if (this.TJM === null || this.ProximitySupportETP === null) return 0;
      return (
        (this.ProximitySupportETP *
          this.TJM *
          this.proximityTicketsWSrate *
          this.nbDaysWorked) /
        100
      );
    },
    SupportCosts: function () {
      if (this.nbUsers == null || this.nbUsers == 0 || this.nbUsers == "")
        return 0;
      return Math.round(
        (((Number(this.DistantSupportCost) +
          Number(this.ProximitySupportCost)) /
          this.nbUsers) *
          100) /
          100
      );
    },
    consoCosts: function () {
      if (this.nbUsers == null || this.nbUsers == 0 || this.nbUsers == "")
        return 0;
      var conso = 0;
      for (let HWCategory of this.HWCategories) {
        conso += HWCategory.conso * HWCategory.quantity;
      }
      conso = Math.round(((conso / this.nbUsers) * this.prixKWH * 100) / 100);
      return isNaN(conso) ? 0 : conso;
    },
    totalCosts: function () {
      return Math.round(
        this.HardwareCosts * 1 +
          this.IngenieringCosts * 1 +
          this.InfraServicesCosts * 1 +
          this.ServicesCosts * 1 +
          this.SupportCosts * 1 +
          this.consoCosts * 1
      );
    },
    // chartData: function () {
    //   return {
    //     labels: [
    //       "Coûts matériels",
    //       "consomation électrique",
    //       "coût d'ingénierie",
    //     ],
    //     datasets: [
    //       {
    //         backgroundColor: ["#f87979", "#79f879", "#7979f8"],
    //         data: [this.HardwareCosts, this.consoCosts, this.IngenieringCosts],
    //       },
    //     ],
    //   };
    // },
  },
  methods: {
    selectField: function (fieldID) {
      this.voletActif = 0;
      console.log(fieldID);
      console.log("document.getElementById(" + fieldID + ").outerHTML");
      console.log(document.getElementById(fieldID).outerHTML);
      this.$nextTick(() => {
        document.getElementById(fieldID).focus();
      });
    },
    switchDefHWState: function () {
      this.defHWCostsDirectly = !this.defHWCostsDirectly;
    },
    resetNewHW: function () {
      this.newHW = {
        name: null,
        icon: "",
        quantity: null,
        cost: null,
        dureeAmo: null,
        conso: 600,
      };
    },
    cancellHWModification: function () {
      this.resetNewHW();
      this.showNewHWForm = false;
      this.showModifyHWForm = false;
    },
    saveNewHW: function () {
      this.HWCategories.push(this.newHW);
      console.log(this.HWCategories);
      this.$nextTick(() => {
        this.resetNewHW();
        this.showNewHWForm = false;
      });
    },
    saveHWModification: function () {
      for (var i in this.HWCategories[this.newHW.modify]) {
        this.HWCategories[this.newHW.modify][i] = this.newHW[i];
      }
      console.log(this.HWCategories);
      this.resetNewHW;
      this.$nextTick(() => {
        this.showModifyHWForm = false;
      });
    },
    modifyHW: function (idHW) {
      for (var i in this.HWCategories[idHW]) {
        this.newHW[i] = this.HWCategories[idHW][i];
      }
      this.newHW.modify = idHW;
      this.$nextTick(() => {
        this.showModifyHWForm = true;
      });
    },
    deleteHW: function (idHW) {
      var newHWList = this.HWCategories.slice(0, idHW + 1);
      newHWList.pop();
      newHWList = newHWList.concat(this.HWCategories.slice(idHW + 1));
      this.HWCategories = newHWList;
      this.resetNewHW;
      this.$nextTick(() => {
        this.showNewHWForm = false;
        this.showModifyHWForm = false;
      });
    },
    // saveClientsData() {
    //   console.log("test d'enregistrement du nom du client");
    //   var clientData = {
    //     FirstName: this.clientFirstName,
    //     Name: this.clientName,
    //     email: this.email,
    //     entreprise: this.entreprise,
    //     poste: this.poste,
    //     souhaits: this.clientWhishes,
    //     nbUsers: this.nbUsers,
    //     TJM: this.TJM,
    //   };
    //   if (this.defHWCostsDirectly) {
    //     clientData.HWCosts = this.HWCostsState;
    //   } else {
    //     clientData.HWCosts = this.HardwareCosts;
    //     clientData.HWCategories = this.HWCategories;
    //   }
    //   donneesClientRef.push(clientData);
    // },
    saveClientsData() {
      console.log("test d'enregistrement du nom du client");
      var clientData = {
        FirstName: this.clientFirstName,
        Name: this.clientName,
        email: this.email,
        entreprise: this.entreprise,
        clientSectors: this.clientSectors,
        poste: this.poste,
        souhaits: this.clientWhishes,
        nbUsers: this.nbUsers,
        nbDaysWorked: this.nbDaysWorked,
        TJM: this.TJM,

        prixAccessoirsLaptops: this.prixAccessoirsLaptops,
        prixAccessoirsDesktops: this.prixAccessoirsDesktops,
        prixKWH: this.prixKWH,

        ImageETP: this.ImageETP,
        SCCMmcoETP: this.SCCMmcoETP,
        PatchMgtETP: this.PatchMgtETP,
        SecurityETP: this.SecurityETP,

        PackageCost: this.PackageCost,
        PackageNumber: this.PackageNumber,
        AnnualPackageRenewalRate: this.AnnualPackageRenewalRate,

        NetworkEngineeringETP: this.NetworkEngineeringETP,
        NetworkWSRate: this.NetworkWSRate,
        WiFiCost: this.WiFiCost,
        WANCost: this.WANCost,
        VPNCost: this.VPNCost,

        SCCMLicenceCost: this.SCCMLicenceCost,
        SCCMInfraCosts: this.SCCMInfraCosts,
        SCCMInfraDepreciation: this.SCCMInfraDepreciation,
        SCCMInfraLicenceCost: this.SCCMInfraLicenceCost,

        AntivirusLicenceCost: this.AntivirusLicenceCost,
        AVInfraCosts: this.AVInfraCosts,
        AVInfraDepreciation: this.AVInfraDepreciation,
        AVInfraLicence: this.AVInfraLicence,

        IAMWSRate: this.IAMWSRate,

        ADLicenceCost: this.ADLicenceCost,
        ADInfraCosts: this.ADInfraCosts,
        ADInfraDepreciation: this.ADInfraDepreciation,
        ADInfraLicenceCost: this.ADInfraLicenceCost,

        MFACosts: this.MFACosts,

        SSOCosts: this.SSOCosts,

        IPKILicenceCost: this.IPKILicenceCost,
        IPKIInfraCost: this.IPKIInfraCost,
        IPKIInfraDepreciation: this.IPKIInfraDepreciation,
        IPKIInfraLicenceCost: this.IPKIInfraLicenceCost,

        PrintingLicenceCost: this.PrintingLicenceCost,
        PrintingInfraCost: this.PrintingInfraCost,
        PrintingInfraDepreciation: this.PrintingInfraDepreciation,
        PrintingInfraLicenceCost: this.PrintingInfraLicenceCost,
        NbPrinters: this.NbPrinters,
        PrintersCosts: this.PrintersCosts,
        PrintersDepreciation: this.PrintersDepreciation,

        MailLicences: this.MailLicences,
        MailInfraCost: this.MailInfraCost,
        MailInfraDepreciation: this.MailInfraDepreciation,
        MailInfraLicenceCost: this.MailInfraLicenceCost,

        OfficeSuiteLicenceCost: this.OfficeSuiteLicenceCost,

        ChatLicence: this.ChatLicence,

        GEDLicence: this.GEDLicence,
        GEDInfraCost: this.GEDInfraCost,
        GEDInfraDepreciation: this.GEDInfraDepreciation,
        GEDInfraLicenceCost: this.GEDInfraLicenceCost,

        proximitySupportTicketBased: this.proximitySupportTicketBased,
        ProximitySupportETP: this.ProximitySupportETP,
        ProximityTicketYealryQte: this.ProximityTicketYealryQte,
        proximitySupportTicketCost: this.proximitySupportTicketCost,
        proximityTicketsWSrate: this.proximityTicketsWSrate,

        DistantSupportTicketBased: this.DistantSupportTicketBased,
        L1SupportETP: this.L1SupportETP,
        L2SupportETP: this.L2SupportETP,
        L3SupportETP: this.L3SupportETP,
        DistantSupportTicketYealyQte: this.DistantSupportTicketYealyQte,
        DistantSupportTicketCost: this.DistantSupportTicketCost,
        DistantTicketsWSRate: this.DistantTicketsWSRate,
        defHWCostsDirectly: this.defHWCostsDirectly,
      };
      if (this.defHWCostsDirectly) {
        clientData.HWCosts = this.HWCostsState;
      } else {
        clientData.HWCosts = this.HardwareCosts;
        clientData.HWCategories = this.HWCategories;
      }
      const newClientDataRef = push(
        child(ref(database), "/donneesTCOClients")
      ).key;
      const updates = {};
      updates["/donneesTCOClients/" + newClientDataRef] = clientData;
      update(ref(database), updates);
    },
    retrieveClientData() {
      var idClient = this.$route.params.id;
      console.log("récupération du TCO du client " + idClient);
      const dbRef = ref(getDatabase());
      get(child(dbRef, `donneesTCOClients/${idClient}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            console.log(snapshot.val());
            this.clientFirstName = snapshot.val().FirstName;
            this.clientName = snapshot.val().Name;
            this.email = snapshot.val().email;
            this.entreprise = snapshot.val().entreprise;
            this.clientSectors = snapshot.val().clientSectors;
            this.poste = snapshot.val().poste;
            this.clientWhishes = snapshot.val().souhaits;
            this.nbUsers = snapshot.val().nbUsers;
            this.TJM = snapshot.val().TJM;
            this.nbDaysWorked = snapshot.val().nbDaysWorked;

            this.prixAccessoirsLaptops = snapshot.val().prixAccessoirsLaptops;
            this.prixAccessoirsDesktops = snapshot.val().prixAccessoirsDesktops;
            this.prixKWH = snapshot.val().prixKWH;

            this.ImageETP = snapshot.val().ImageETP;
            this.SCCMmcoETP = snapshot.val().SCCMmcoETP;
            this.PatchMgtETP = snapshot.val().PatchMgtETP;
            this.SecurityETP = snapshot.val().SecurityETP;

            this.PackageCost = snapshot.val().PackageCost;
            this.PackageNumber = snapshot.val().PackageNumber;
            this.AnnualPackageRenewalRate =
              snapshot.val().AnnualPackageRenewalRate;

            this.NetworkEngineeringETP = snapshot.val().NetworkEngineeringETP;
            this.NetworkWSRate = snapshot.val().NetworkWSRate;
            this.WiFiCost = snapshot.val().WiFiCost;
            this.WANCost = snapshot.val().WANCost;
            this.VPNCost = snapshot.val().VPNCost;

            this.SCCMLicenceCost = snapshot.val().SCCMLicenceCost;
            this.SCCMInfraCosts = snapshot.val().SCCMInfraCosts;
            this.SCCMInfraDepreciation = snapshot.val().SCCMInfraDepreciation;
            this.SCCMInfraLicenceCost = snapshot.val().SCCMInfraLicenceCost;

            this.AntivirusLicenceCost = snapshot.val().AntivirusLicenceCost;
            this.AVInfraCosts = snapshot.val().AVInfraCosts;
            this.AVInfraDepreciation = snapshot.val().AVInfraDepreciation;
            this.AVInfraLicence = snapshot.val().AVInfraLicence;

            this.IAMWSRate = snapshot.val().IAMWSRate;

            this.ADLicenceCost = snapshot.val().ADLicenceCost;
            this.ADInfraCosts = snapshot.val().ADInfraCosts;
            this.ADInfraDepreciation = snapshot.val().ADInfraDepreciation;
            this.ADInfraLicenceCost = snapshot.val().ADInfraLicenceCost;

            this.MFACosts = snapshot.val().MFACosts;

            this.SSOCosts = snapshot.val().SSOCosts;

            this.IPKILicenceCost = snapshot.val().IPKILicenceCost;
            this.IPKIInfraCost = snapshot.val().IPKIInfraCost;
            this.IPKIInfraDepreciation = snapshot.val().IPKIInfraDepreciation;
            this.IPKIInfraLicenceCost = snapshot.val().IPKIInfraLicenceCost;

            this.PrintingLicenceCost = snapshot.val().PrintingLicenceCost;
            this.PrintingInfraCost = snapshot.val().PrintingInfraCost;
            this.PrintingInfraDepreciation =
              snapshot.val().PrintingInfraDepreciation;
            this.PrintingInfraLicenceCost =
              snapshot.val().PrintingInfraLicenceCost;
            this.NbPrinters = snapshot.val().NbPrinters;
            this.PrintersCosts = snapshot.val().PrintersCosts;
            this.PrintersDepreciation = snapshot.val().PrintersDepreciation;

            this.MailLicences = snapshot.val().MailLicences;
            this.MailInfraCost = snapshot.val().MailInfraCost;
            this.MailInfraDepreciation = snapshot.val().MailInfraDepreciation;
            this.MailInfraLicenceCost = snapshot.val().MailInfraLicenceCost;

            this.OfficeSuiteLicenceCost = snapshot.val().OfficeSuiteLicenceCost;

            this.ChatLicence = snapshot.val().ChatLicence;

            this.GEDLicence = snapshot.val().GEDLicence;
            this.GEDInfraCost = snapshot.val().GEDInfraCost;
            this.GEDInfraDepreciation = snapshot.val().GEDInfraDepreciation;
            this.GEDInfraLicenceCost = snapshot.val().GEDInfraLicenceCost;

            this.proximitySupportTicketBased =
              snapshot.val().proximitySupportTicketBased;
            this.ProximitySupportETP = snapshot.val().ProximitySupportETP;
            this.ProximityTicketYealryQte =
              snapshot.val().ProximityTicketYealryQte;
            this.proximitySupportTicketCost =
              snapshot.val().proximitySupportTicketCost;
            this.proximityTicketsWSrate = snapshot.val().proximityTicketsWSrate;

            this.DistantSupportTicketBased =
              snapshot.val().DistantSupportTicketBased;
            this.L1SupportETP = snapshot.val().L1SupportETP;
            this.L2SupportETP = snapshot.val().L2SupportETP;
            this.L3SupportETP = snapshot.val().L3SupportETP;
            this.DistantSupportTicketYealyQte =
              snapshot.val().DistantSupportTicketYealyQte;
            this.DistantSupportTicketCost =
              snapshot.val().DistantSupportTicketCost;
            this.DistantTicketsWSRate = snapshot.val().DistantTicketsWSRate;

            if (snapshot.val().defHWCostsDirectly == true) {
              this.HWCostsState = snapshot.val().HWCosts;
            } else {
              this.HardwareCosts = snapshot.val().HWCosts;
              this.HWCategories = snapshot.val().HWCategories;
            }
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
      return idClient;
    },
    validateClientForm() {
      this.$refs.clientForm.validate();
      this.saveClientsData();
      this.showClientForm = false;
    },
    resetClientForm() {
      this.$refs.clientForm.reset();
      this.clientWhishes.livreBlanc = false;
      this.clientWhishes.joinCommunity = false;
      this.clientWhishes.slides = false;
      this.showClientForm = false;
    },
    resetValidationClientForm() {
      this.$refs.clientForm.resetValidation();
    },
  },
  beforeMount() {
    this.retrieveClientData();
  },
  // components: {
  //   donutChart
  // }
};
</script>
