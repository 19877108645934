import Vue from "vue";
import VueRouter from "vue-router";
import CalculetteView from "../views/CalculetteView.vue";
import CalculetteViewColor from "../views/CalculetteViewColor.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "calculetteView",
    component: CalculetteView,
  },
  {
    path: "/color",
    name: "calculetteViewColor",
    component: CalculetteViewColor,
  },
  {
    path: "/:id",
    name: "calculetteView",
    component: CalculetteView,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
