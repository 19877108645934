import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // Créer ou modifier les couleurs de l'application
        primary: "#336173",
        secondary: "#F6A141",
        accent: "#3C93B6",
        success: "#70AD47",
        warning: "#FFC107",
        error: "#E84646",
        info: "#2196F3",
        grey: "#A7A7A7",
        black: "1B1B1B",
        white: "#FFFFFF",
      },
    },
  },
});
